import { Button } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import "./styles.scss";
import ProductService from "src/services/API/Product";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store/configureStore";
import { ProductItem, ProductPublicStatus } from "src/models/product.model";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setAppLoading } from "src/store/State";
import { Desktop, Mobile } from "src/layouts/break-point";
import { ReactComponent as Checked } from "@components/Icons/icon-checked.svg";
import { ReactComponent as Uncheck } from "@components/Icons/icon-uncheck.svg";
import { DATE_FORMAT } from "src/configs";
import AppModal from "../AppModal";
import AppShowMedia from "../AppShowMedia";

interface Props {
  id: number;
  onConfirm: any;
}

const PublicForm: FC<Props> = ({ ...props }) => {
  const navigate = useNavigate();

  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );

  const { t: translate } = useTranslation();

  const [product, setProduct] = useState<ProductItem>();

  const [showConfirm, setShowConfirm] = useState(false);

  const [showSuccess, setShowSuccess] = useState(false);

  const dispatch = useDispatch();

  const onOk = () => {
    setShowConfirm(false);
    if (product) {
      dispatch(setAppLoading(true));
      ProductService.updateStatusProductProduct({
        productId: Number(product?.id),
        status: ProductPublicStatus.Published,
        userToken: product.canPublish ? product.userToken : "",
      })
        .then((res) => {
          if (res.data.result) {
            setShowSuccess(true);
            dispatch(setAppLoading(false));
          }
        })
        .catch((error) => {
          dispatch(setAppLoading(false));
        });
    }
  };

  const onOkSuccess = () => {
    setShowSuccess(false);
    navigate("/my-page/sales-history?tab=2");
  };

  useEffect(() => {
    if (!props.id || props.id < 0) {
      return;
    }
    ProductService.getProductById(props.id)
      .then((res) => {
        setProduct(res.data.result);
      })
      .catch((err) => {});
  }, []);

  function navigateToHistory() {
    navigate("/my-page/sales-history?tab=2");
  }

  return (
    <>
      <AppModal
        visible={showConfirm}
        title={translate("public.product.public.confirm.title")}
        content={translate("public.product.public.confirm.content")}
        cancelText={translate("public.product.save.cancel")}
        okText={translate("public.product.save.ok")}
        onCancel={() => {
          setShowConfirm(false);
        }}
        onOk={onOk}
      />

      <AppModal
        visible={showSuccess}
        title={translate("public.product.public.success.content")}
        content={moment().format("YYYY/MM/DD HH:mm")}
        cancelText={undefined}
        okText={translate("contact.action.close")}
        onOk={onOkSuccess}
        icon={<Success />}
      />
      <Mobile>
        <div className="public-form-container">
          <div className="header-container">
            <div className="video-container">
              <AppShowMedia productMedias={product?.productMedias} />
            </div>
          </div>

          <div className="product-content">
            <div className="content-info">
              <div className="product-name color-title text-body-1 bold">
                {product?.title}
              </div>
              <div className="row-price-view">
                <div className="price color-secondary text-body-2 bold">
                  <span className="bold">
                    {translate("product.detail.price")}:
                  </span>
                  <span className="text-body-4">¥</span>
                  <NumberFormat
                    value={product?.price}
                    displayType="text"
                    thousandSeparator
                  />
                </div>
              </div>
            </div>
            <div className="content-overview">
              <div className="title color-title text-body-2 bold">
                {translate("product.detail.overview")}
              </div>
              <div className="content">
                {/* {(product?.productMedias?.filter((item) => item.type === 2)
                  .length as number) > 0 && (
                  <div className="row-info">
                    <div className="i-title color-body text-body-4">
                      <span className="bold box-checked">
                        {product?.isPostVideoToYoutube ? (
                          <Checked />
                        ) : (
                          <Uncheck />
                        )}
                      </span>

                      {translate("product.detail.upload.youtube")}
                    </div>
                  </div>
                )} */}

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.category")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {ProductService.getGroupCategoryString(
                      listCategory,
                      product?.categoryId
                    )}
                  </div>
                </div>

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.maker")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {product?.maker}
                  </div>
                </div>

                {/* <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.title")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {product?.title}
                  </div>
                </div> */}

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.year")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {product?.yearOfManufacture
                      ? product?.yearOfManufacture >= 1980
                        ? product?.yearOfManufacture
                        : "1980年以前"
                      : "年式不明"}
                  </div>
                </div>

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.keyword")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {product?.keyword}
                  </div>
                </div>

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.comment")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {product?.comment}
                  </div>
                </div>

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.location")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {product?.city} {product?.district}
                  </div>
                </div>

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.price")}
                  </div>
                  <div className="i-value color-primary text-body-4 bold">
                    <span className="text-caption-2">¥</span>
                    <NumberFormat
                      value={product?.price}
                      displayType="text"
                      thousandSeparator
                    />
                  </div>
                </div>

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.commission")}
                  </div>
                  <div className="i-value color-primary text-body-4 bold">
                    <span className="text-caption-2">¥</span>
                    <NumberFormat
                      value={product?.commissionAmountOnTax}
                      displayType="text"
                      thousandSeparator
                    />
                    <span className="color-title text-caption-2">（内税）</span>
                  </div>
                </div>

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.posting.end.date")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {product?.endDate
                      ? moment(product?.endDate, DATE_FORMAT).format("YYYY年MM月DD日")
                      // : "年式不明"}
                      : "未公開"}
                  </div>
                </div>

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    <span className="color-title text-body-4 bold box-checked">
                      {product?.isAllowOnlineTours ? <Checked /> : <Uncheck />}
                    </span>
                    <span>{translate("product.detail.visit.online")}</span>
                  </div>
                </div>

                <div className="row-info">
                  <div className="i-title color-body text-body-4">
                    {translate("product.detail.person.payment.ship")}
                  </div>
                  <div className="i-value color-title text-body-4 bold">
                    {product?.carryingCost === 1 ? "販売者" : "購入者"}
                  </div>
                </div>

                {/* <div className="row-info"> */}
                {/*  <div className="i-title color-body text-body-4"> */}
                {/*    <span className="i-value color-title text-body-4 bold box-checked"> */}
                {/*      {product?.isNeedTakeBackQuote ? <Checked /> : <Uncheck />} */}
                {/*    </span> */}
                {/*    <span>{translate("product.detail.request.quotation")}</span> */}
                {/*  </div> */}
                {/* </div> */}

                {/* {product?.isNeedTakeBackQuote && ( */}
                {/*  <div className="row-info"> */}
                {/*    <div className="i-title color-body text-body-4"> */}
                {/*      {translate("product.detail.desired.date.quotation")} */}
                {/*    </div> */}
                {/*    <div className="i-value color-title text-body-4 bold"> */}
                {/*      {moment(product?.estimationDate, DATE_FORMAT).format("YYYY年MM月DD日")} */}
                {/*    </div> */}
                {/*  </div> */}
                {/* )} */}
              </div>
            </div>
          </div>

          {product?.status === ProductPublicStatus.Unpublish && (
            <div className="note-publish">
              <InfoCircleOutlined />{" "}
              この商品は公開されていないので購入者は検索できません。
            </div>
          )}

          <div className="button-container">
            <Button className="button-secondary" onClick={navigateToHistory}>
              {translate("signup.success.exit")}
            </Button>
          </div>
        </div>
      </Mobile>

      <Desktop>
        <div className="public-form-container">
          <div className="form-desktop-container">
            <div className="header-container">
              <div className="video-container">
                <AppShowMedia productMedias={product?.productMedias} />
              </div>
            </div>
            <div className="container-product-content">
              <div className="product-content">
                <div className="content-info">
                  <div className="product-name color-title text-body-1 bold">
                    {product?.title}
                  </div>
                  <div className="row-price-view">
                    <div className="price color-secondary text-body-2 bold">
                      <span className="bold">
                        {translate("product.detail.price")}:
                      </span>
                      <span className="text-body-4">¥</span>
                      <NumberFormat
                        value={product?.price}
                        displayType="text"
                        thousandSeparator
                      />
                    </div>
                    {/* <div className="view-like-container color-title text-body-4">
                    <EyeOutlined />
                    <span className="text-value">
                      {(product?.viewCount || 0) > 999
                        ? "999+"
                        : product?.viewCount}
                    </span>
                    <HeartOutlined />
                    <span className="text-value">
                      {(product?.favoriteCount || 0) > 999
                        ? "999+"
                        : product?.favoriteCount}
                    </span>
                  </div> */}
                  </div>
                </div>
                <div className="content-overview">
                  <div className="title color-title text-body-2 bold">
                    {translate("product.detail.overview")}
                  </div>
                  <div className="content">
                    {/* {(product?.productMedias?.filter((item) => item.type === 2)
                      .length as number) > 0 && (
                      <div className="row-info">
                        <div className="i-title color-body text-body-4">
                          <span className="color-title text-body-4 bold box-checked">
                            {product?.isPostVideoToYoutube ? (
                              <Checked />
                            ) : (
                              <Uncheck />
                            )}
                          </span>
                          <span>
                            {" "}
                            {translate("product.detail.upload.youtube")}
                          </span>
                        </div>
                      </div>
                    )} */}

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.category")}
                      </div>
                      <div className="i-value color-title text-body-4 bold">
                        {ProductService.getGroupCategoryString(
                          listCategory,
                          product?.categoryId
                        )}
                      </div>
                    </div>

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.maker")}
                      </div>
                      <div className="i-value color-title text-body-4 bold">
                        {product?.maker}
                      </div>
                    </div>

                    {/* <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.title")}
                      </div>
                      <div className="i-value color-title text-body-4 bold">
                        {product?.title}
                      </div>
                    </div> */}

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.year")}
                      </div>
                      <div className="i-value color-title text-body-4 bold">
                        {product?.yearOfManufacture
                          ? product?.yearOfManufacture >= 1980
                            ? product?.yearOfManufacture
                            : "1980年以前"
                          : "年式不明"}
                      </div>
                    </div>

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.keyword")}
                      </div>
                      <div className="i-value color-title text-body-4 bold">
                        {product?.keyword}
                      </div>
                    </div>

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.comment")}
                      </div>
                      <div className="i-value color-title text-body-4 bold">
                        {product?.comment}
                      </div>
                    </div>

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.location")}
                      </div>
                      <div className="i-value color-title text-body-4 bold">
                        {product?.city} {product?.district}
                      </div>
                    </div>

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.price")}
                      </div>
                      <div className="i-value color-primary text-body-4 bold">
                        <span className="text-caption-2">¥</span>
                        <NumberFormat
                          value={product?.price}
                          displayType="text"
                          thousandSeparator
                        />
                      </div>
                    </div>

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.commission")}
                      </div>
                      <div className="i-value color-primary text-body-4 bold">
                        <span className="text-caption-2">¥</span>
                        <NumberFormat
                          value={product?.commissionAmountOnTax}
                          displayType="text"
                          thousandSeparator
                        />
                        <span className="color-title text-caption-2">
                          （内税）
                        </span>
                      </div>
                    </div>

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.posting.end.date")}
                      </div>
                      <div className="i-value color-title text-body-4 bold">
                        {product?.endDate
                          ? moment(product?.endDate, DATE_FORMAT).format("YYYY年MM月DD日")
                          : "年式不明"}
                      </div>
                    </div>

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        <span className="i-value color-title text-body-4 bold box-checked">
                          {product?.isAllowOnlineTours ? (
                            <Checked />
                          ) : (
                            <Uncheck />
                          )}
                        </span>
                        <span>{translate("product.detail.visit.online")}</span>
                      </div>
                    </div>

                    <div className="row-info">
                      <div className="i-title color-body text-body-4">
                        {translate("product.detail.person.payment.ship")}
                      </div>
                      <div className="i-value color-title text-body-4 bold">
                        {product?.carryingCost === 1 ? "販売者" : "購入者"}
                      </div>
                    </div>

                    {/* <div className="row-info"> */}
                    {/*  <div className="i-title color-body text-body-4"> */}
                    {/*    <span className="i-value color-title text-body-4 bold box-checked"> */}
                    {/*      {product?.isNeedTakeBackQuote ? ( */}
                    {/*        <Checked /> */}
                    {/*      ) : ( */}
                    {/*        <Uncheck /> */}
                    {/*      )} */}
                    {/*    </span> */}
                    {/*    <span> */}
                    {/*      {translate("product.detail.request.quotation")} */}
                    {/*    </span> */}
                    {/*  </div> */}
                    {/* </div> */}

                    {/* {product?.isNeedTakeBackQuote && ( */}
                    {/*  <div className="row-info"> */}
                    {/*    <div className="i-title color-body text-body-4"> */}
                    {/*      {translate("product.detail.desired.date.quotation")} */}
                    {/*    </div> */}
                    {/*    <div className="i-value color-title text-body-4 bold"> */}
                    {/*      {moment(product?.estimationDate, DATE_FORMAT).format( */}
                    {/*        "YYYY年MM月DD日" */}
                    {/*      )} */}
                    {/*    </div> */}
                    {/*  </div> */}
                    {/* )} */}
                  </div>
                </div>
              </div>
              {product?.status === ProductPublicStatus.Unpublish && (
                <div className="note-publish color-body">
                  <InfoCircleOutlined />
                  この商品は公開されていないので購入者は検索できません。
                </div>
              )}
            </div>
          </div>

          <div className="button-container">
            <Button className="button-secondary" onClick={navigateToHistory}>
              {translate("signup.success.exit")}
            </Button>
          </div>
        </div>
      </Desktop>
    </>
  );
};

export default PublicForm;
