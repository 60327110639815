import React, { FC, memo, useCallback, useEffect, useState } from "react";
import "./home.scss";
import { Tabs } from "antd";
import {
  setFilter,
  setStatusFilter,
  setCurrentCategory,
  ProductFilterState,
  setAllState,
  setKeyword,
  ProductFilter,
  clearAll,
} from "src/store/Product-Filter";
import ProductService from "src/services/API/Product";
import { useDispatch, useSelector } from "react-redux";
import AppStore, { RootState } from "src/store/configureStore";
import { AppDispatch } from "@store/configureStore";
import { Category } from "src/models/product.model";
import classNames from "classnames";
import { cloneDeep, get, isEmpty, isEqual, xor } from "lodash";
import { ReactComponent as FilterIcon } from "@components/Icons/filter-setting.svg";
import { useMediaQuery } from "react-responsive";
import useHideBottomBar from "src/hooks/useHideBottomBar";
import { useTranslation } from "react-i18next";
import SearchFilterForm from "src/components/SearchFilterDesktop";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppSkeletonSlider from "src/components/skeleton/AppSkeletonSlider";
import CommonService from "src/services/Common";
import { Desktop, Mobile } from "src/layouts/break-point";
import { DeviceType } from "src/models/common.model";
import GlobalService from "src/services/API/Global";
import { setError } from "src/store/State";
import { getListCategory as getListCategoryAction } from '@store/Product';
import { getListBanner, getListSlider } from '@store/Global';
import AppModal from '@components/AppModal';
import SearchResult from "./search-result";
import DashboardScreen from "./dashboard";
import SearchResultDashboard from "./search-result-dashboard";
import { PATH_NAME } from "../../configs/index";
import AppCategorySkeleton from "../../components/skeleton/AppCategorySketeton";
import UserService from "../../services/API/User/index";

export const CATEGORY_ALL = "1000";

interface DesktopProps {
  loading: boolean
}

const DesktopView: FC<DesktopProps> = (props) => {
  const dispatch = useDispatch<AppDispatch>();
  const statusFilter = useSelector(
    (state: RootState) => state.ProductFilterReducer.statusFilter
  );
  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  const [listFilterCategory, setListFilterCategory] = useState<Category[]>([]);

  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategoryOnDashboard
  );
  const [confirmLogin, setConfirmLogin] = useState<boolean>(false);

  useEffect(() => {
    if (!get(listCategory, "length")) {
      return;
    }

    const listConvert =
      ProductService.getCategoryDisplayOnDashboard(listCategory);

    setListFilterCategory(listConvert || []);
  }, [listCategory]);

  const navigate = useNavigate();
  const listBanner = useSelector(
    (state: RootState) => state.GlobalReducer.banner
  );

  function renderChildCategory(category: Category) {
    return (
      <div
        className="child-category"
        key={category.id}
        onClick={() => {
          dispatch(
            setStatusFilter({
              status: true,
              categoryId: Number(category.id),
              categories: category.childrenEnd?.map((x) => x.id) || [
                category.id,
              ],
            })
          );
        }}
      >
        <img
          src={
            category.imageUrl ? category.imageUrl.concat(sasCode as string) : "/assets/images/fake10.png"
          }
          alt=""
        />
        <div className="left-view-side">
          <div className="c-name text-body-4 bold three-dot">
            {category.name}
          </div>
          <div className="c-des text-body-4">{category.description}</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <AppModal
        visible={confirmLogin}
        title="この先の機能はログインが必要となります。"
        content=""
        okText="ログイン"
        cancelText="閉じる"
        onOk={() => navigate(`${PATH_NAME.LOGIN}?returnUrl=/shop`)}
        onCancel={() => {
          setConfirmLogin(false);
        }}
      />
      <div className="desktop-db-view">
        <div className={classNames("left-db", { "on-filter": statusFilter })}>
          {!statusFilter && (
            <>
              <div
                className="lg-button-filter"
                onClick={() => {
                  dispatch(setStatusFilter({ status: true }));
                }}
              >
                <div className="bold">絞り込み検索</div>
                <FilterIcon />
              </div>
              <div className="lg-button-category">
                <div>人気のカテゴリーから探す</div>
              </div>
              <div className="hidden-scrollbar scrollbar-container">
                {isEmpty(listFilterCategory)
                  ? [...Array(6)].map((x, i) => <AppCategorySkeleton key={i} />)
                  : listFilterCategory
                    // .slice(0, 3)
                    .map((category) => renderChildCategory(category))}
              </div>
              <div
                className="lg-button-action"
                onClick={() => {
                  dispatch(setStatusFilter({ status: true }));
                }}
              >
                上記以外のカテゴリーから探す
              </div>
            </>
          )}

          {statusFilter && (
            <SearchFilterForm onCancel={() => {}} onOk={() => {}} />
          )}
        </div>
        {statusFilter && (
          <div className="center-db">
            <SearchResultDashboard results={[]} />
          </div>
        )}

        {!statusFilter && (
          <div className="center-db">
            <DashboardScreen />
          </div>
        )}
        {!statusFilter && (
          <div className="right-db hidden-scrollbar">
            <div
              className="img-container"
              onClick={() => {
                if (UserService.isAuthenticated()) {
                  navigate(`/${PATH_NAME.SHOP}`);
                } else {
                  setConfirmLogin(true);
                }
              }}
            >
              <img src="/assets/images/banner1.png" alt="" />
            </div>

            <div
             className="img-container"
             onClick={() => {
                CommonService.openURL("https://mashipla.com/guide/");
              }}
            >
              <img src="/assets/images/guide_for_buyer.png" alt="" />
            </div>

            <div
             className="img-container"
             onClick={() => {
                CommonService.openURL("https://mashipla.com/guide/");
              }}
            >
              <img src="/assets/images/guide_for_seller.png" alt="" />
            </div>

            {props.loading
              ? [...Array(7)].map((x, i) => (
                <AppSkeletonSlider className="img-container" key={i} />
              ))
              : isEmpty(listBanner) ? null
                : listBanner.map(
                  (item, index) =>
                    (item.device === DeviceType.WEB ||
                      item.device === DeviceType.BOTH) && (
                      <div
                        key={index}
                        className="img-container"
                        onClick={() => {
                          GlobalService.handleClickBanner(item.id);
                          CommonService.openURL(item.externalUrl);
                        }}
                      >
                        <img src={item.imageUrl.concat(sasCode as string)} alt="" />
                      </div>
                    )
                )}
          </div>
        )}
      </div>
    </>
  );
};

const MobileView: FC = () => {
  useHideBottomBar(false);

  const [activeKey, setActiveKey] = useState<string>("-1");

  const [listHasValue, setListHasValue] = useState<number[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const statusFilter = useSelector(
    (state: RootState) => state.ProductFilterReducer.statusFilter
  );

  const { t: translate } = useTranslation();
  const { TabPane } = Tabs;
  const [listFilterCategory, setListFilterCategory] = useState<Category[]>([]);

  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );

  const currentCategory = useSelector(
    (state: RootState) => state.ProductFilterReducer.currentCategorySelect
  );

  const productFilter = useSelector(
    (state: RootState) => state.ProductFilterReducer.filter
  );

  useEffect(() => {
    const ROOT_CATEGORIES = [2, 4];
    if (!get(listCategory, "length")) {
      return;
    }

    if (productFilter?.categories && get(productFilter, "categories.length")) {
      const listFilterCategory = listCategory.filter(
        (f) => (productFilter?.categories || []).indexOf(f.id) > -1
      );

      let listSecondCategory: Array<Category> = [];

      if (productFilter.categories) {
        if (!ROOT_CATEGORIES.includes(productFilter.categories[0])) {
          listSecondCategory =
            ProductService.getSecondCategoryFromLastCategory(
              listCategory,
              listFilterCategory
            );
        } else {
          listSecondCategory = listCategory.filter(category => category.parentId === productFilter.categories?.[0]);
        }
      }

      setListFilterCategory(cloneDeep(listSecondCategory || []));
      // setListFilterCategory(cloneDeep(listFilterCategory || []));

      setTimeout(() => {
        setActiveKey(
          listSecondCategory.length === 1
            ? `${listSecondCategory[0]?.id}`
            : CATEGORY_ALL
        );
      });
    } else {
      const listConvert = ProductService.getSecondCategory(listCategory);

      setListFilterCategory(listConvert || []);

      if (statusFilter) {
        setActiveKey(currentCategory || CATEGORY_ALL);
      }
    }
  }, [listCategory, productFilter]);

  // useEffect(() => {
  //   setListHasValue(listFilterCategory.map((item) => item.id));
  // }, [listCategory, productFilter]);

  useEffect(() => {
    if (!statusFilter) {
      ProductService.getAllCategoryHasValue().then((res) => {
        setListHasValue(res.data.result.map((x: any) => x.id));
      });
    }
  }, [statusFilter]);

  useEffect(() => {
    if (!statusFilter) {
      setActiveKey("-1");
    } else {
      setActiveKey(
        currentCategory ||
          `${
            productFilter?.isFilterByType || listFilterCategory.length === 1
              ? listFilterCategory[0]?.id
              : CATEGORY_ALL
          }`
      );
    }
  }, [statusFilter, currentCategory, listFilterCategory, listCategory]);

  const changeTab = (activeKey: string) => {
    if (!statusFilter && activeKey !== "-1") {
      // const category = listFilterCategory.filter(
      //   (f) => f.id === Number(activeKey)
      // )[0];
      dispatch(
        setStatusFilter({
          status: true,
          categoryId: Number(activeKey),
          categories: [],
        })
      );
    }
    dispatch(setCurrentCategory({ categoryId: Number(activeKey) }));
    setActiveKey(activeKey);
  };

  const [categoryIds, setCategoryIds] = useState<number[] | null>(null);

  const onFilterCategory = (categoryIds: number[]) => {
    setCategoryIds(categoryIds);
  };

  useEffect(() => {
    if (listCategory.length && categoryIds) {
      const category = cloneDeep(listCategory);
      const listSelected = category.filter(
        (f) =>
          (categoryIds || []).indexOf(f.id) > -1 || f.id === Number(activeKey)
      );
      const secondaryCategory = ProductService.getCategoryIdFromLastCategory(
        category,
        listSelected
      );
      setListHasValue(secondaryCategory || []);
    }
  }, [listCategory, categoryIds]);

  return (
    <div
      className={classNames("tab-container", {
        "tab-dashboard": activeKey === "-1",
        "filter-by-type": productFilter?.isFilterByType,
        "in-app": (window as any).ReactNativeWebView,
      })}
    >
      <Tabs defaultActiveKey="-1" onChange={changeTab} activeKey={activeKey}>
        {!statusFilter && (
          <TabPane tab={translate("home.dashboard.center")} key="-1">
            <DashboardScreen />
          </TabPane>
        )}

        {statusFilter &&
          !productFilter?.isFilterByType &&
          get(listFilterCategory, "length") !== 1 && (
            <TabPane tab="すべて" key={CATEGORY_ALL} forceRender>
              <SearchResult
                results={[]}
                categoryId={[Number(CATEGORY_ALL)]}
                onFilterCategory={(categoryIds) => {
                  onFilterCategory(categoryIds);
                }}
              />
            </TabPane>
          )}

        {listFilterCategory.map((category: Category, index) => (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {((listHasValue.length !== 0 &&
              listHasValue.includes(category.id)) ||
              Number(activeKey) === category.id) && (
              <TabPane
                tab={category.name}
                key={`${category.id}`}
                className="tab-hidden"
              >
                {statusFilter && Number(activeKey) === category.id && (
                  <SearchResult
                    results={[]}
                    category={category}
                    categoryId={[category.id]}
                  />
                )}
              </TabPane>
            )}
          </>
        ))}
      </Tabs>
    </div>
  );
};

const DashboardTab = memo(() => {
  // const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const listCategory = useSelector(
    (state: RootState) => state.ProductReducer.listCategory
  );

  const provinces = useSelector(
    (state: RootState) => state.GlobalReducer.provinces
  );

  const productFilter = useSelector(
    (state: RootState) => state.ProductFilterReducer
  );

  const [loadingBanner, setLoadingBanner] = useState<boolean>(false);

  const navigate = useNavigate();

  const location = useLocation();

  const { id } = useParams();

  const [searchParam, setSearchParams] = useSearchParams();

  // function navigateWithFilter() {

  //   navigate("/dashboard?filter=true");
  // }

  function convertParamToFilter() {
    const productFilterConvert: ProductFilterState = {
      statusFilter: false,
      // keyword: "",
      sort: {},
      page: 1,
    };

    if (searchParam.get("filter")) {
      productFilterConvert.statusFilter = true;

      if (searchParam.get("keyword")) {
        productFilterConvert.keyword = searchParam.get("keyword") || "";
      }
      // if (searchParam.get("currentCategory") && (searchParam.get("currentCategory") !== productFilterConvert.currentCategorySelect)) {
      //   productFilterConvert.currentCategorySelect =
      //     searchParam.get("currentCategory") || "";
      // }
      let filter!: ProductFilter;

      if (searchParam.get("filterType")) {
        filter = filter || {};

        // eslint-disable-next-line default-case
        switch (searchParam.get("filterType")) {
          case "PICKUP":
            filter.isFilterByType = true;
            filter.isPickup = true;
            filter.isRecently = false;
            break;

          case "RECENTLY":
            filter.isFilterByType = true;
            filter.isPickup = false;
            filter.isRecently = true;
            break;
        }
        productFilterConvert.filter = filter;
      }

      if (searchParam.get("keyword")) {
        productFilterConvert.keyword = searchParam.get("keyword") || "";
      }
      if (searchParam.get("categories")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filter = filter || {};
        filter.categories = searchParam
          .get("categories")
          ?.split(",")
          .map((x) => Number(x));
        productFilterConvert.filter = filter;
      }

      if (searchParam.get("cities")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars

        filter = filter || {};
        if (searchParam.get("cities") === "ALL") {
          filter.citySearch = [null];
        } else {
          filter.citySearch = searchParam.get("cities")?.split(",");
        }
        productFilterConvert.filter = filter;
      }

      if (searchParam.get("maker")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filter = filter || {};
        filter.maker = searchParam.get("maker") || "";
        productFilterConvert.filter = filter;
      }

      if (searchParam.get("yearOfManufactureFrom")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filter = filter || {};
        filter.yearOfManufactureFrom =
          Number(searchParam.get("yearOfManufactureFrom")) || "";
        productFilterConvert.filter = filter;
      }

      if (searchParam.get("yearOfManufactureTo")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filter = filter || {};
        filter.yearOfManufactureTo =
          Number(searchParam.get("yearOfManufactureTo")) || "";
        productFilterConvert.filter = filter;
      }

      if (searchParam.get("priceTo")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filter = filter || {};
        filter.priceTo = Number(searchParam.get("priceTo"));
        productFilterConvert.filter = filter;
      }

      if (searchParam.get("priceFrom")) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filter = filter || {};
        filter.priceFrom = Number(searchParam.get("priceFrom"));
        productFilterConvert.filter = filter;
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (checkParamError()) {
        dispatch(setAllState(productFilterConvert));
      } else {
        dispatch(
          setError({
            message: "C009",
            title: "ページが存在しません。",
            action: () => {
              navigate("/dashboard");
            },
          })
        );
      }
    } else if (location.pathname.includes(`/category/${id}`) && /^\d+$/.test(String(id))) {
      productFilterConvert.statusFilter = true;
      let filter!: ProductFilter;
      // eslint-disable-next-line prefer-const
      filter = filter || {};
      filter.categories = [Number(id)];
      productFilterConvert.filter = filter;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      if (checkParamError()) {
        dispatch(setAllState(productFilterConvert));
      } else {
        dispatch(
          setError({
            message: "C009",
            title: "ページが存在しません。",
            action: () => {
              navigate("/dashboard");
            },
          })
        );
      }
    } else {
      dispatch(clearAll());
    }
  }

  useEffect(() => {
    convertParamToFilter();
  }, [searchParam]);

  function convertToParam() {
    const searchParamPath: any = {};

    if (productFilter.statusFilter) {
      searchParamPath.filter = "true";

      if (productFilter.filter?.isRecently !== productFilter.filter?.isPickup) {
        searchParamPath.filterType = productFilter.filter?.isPickup
          ? "PICKUP"
          : "RECENTLY";
      }

      // if (productFilter.currentCategorySelect && productFilter.currentCategorySelect !==  searchParam.currentCategory) {
      //   searchParam.currentCategory = productFilter.currentCategorySelect;
      // }

      if (productFilter.keyword) {
        searchParamPath.keyword = productFilter.keyword;
      }

      if (
        productFilter.filter &&
        get(productFilter, "filter.categories.length")
      ) {
        searchParamPath.categories =
          productFilter?.filter?.categories?.join(",");
      }
      if (
        productFilter.filter &&
        get(productFilter, "filter.citySearch.length")
      ) {
        if (
          get(productFilter, "filter.citySearch.length") &&
          !get(productFilter, "filter.citySearch[0]")
        ) {
          searchParamPath.cities = "ALL";
        } else {
          searchParamPath.cities = productFilter.filter.citySearch?.join(",");
        }
      }

      if (productFilter.filter && productFilter.filter.maker) {
        searchParamPath.maker = productFilter.filter.maker;
      }

      if (productFilter.filter && productFilter.filter.yearOfManufactureFrom) {
        searchParamPath.yearOfManufactureFrom =
          productFilter.filter.yearOfManufactureFrom;
      }

      if (productFilter.filter && productFilter.filter.yearOfManufactureTo) {
        searchParamPath.yearOfManufactureTo =
          productFilter.filter.yearOfManufactureTo;
      }

      if (productFilter.filter && productFilter.filter.priceFrom) {
        searchParamPath.priceFrom = productFilter.filter.priceFrom;
      }

      if (productFilter.filter && productFilter.filter.priceTo) {
        searchParamPath.priceTo = productFilter.filter.priceTo;
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define

    if (
      !isEmpty(searchParamPath) &&
      !isEqual(searchParamPath, CommonService.paramsToObject(searchParam))
    ) {
      setSearchParams(searchParamPath);
    }
  }

  function checkParamError() {
    if (searchParam.get("categories")) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const listCategories = (searchParam.get("categories") || "")
        .split(",")
        .map((x) => Number(x));
      for (let index = 0; index < listCategories.length; index++) {
        const element = listCategories[index];
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(element)) {
          return false;
        }
      }
      return true;
    }
    return true;
  }

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      convertToParam();
    }
  }, [productFilter]);

  useEffect(() => {
    dispatch(getListCategoryAction());
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingBanner(true);
      try {
        await dispatch(getListBanner());
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingBanner(false);
      }
    })();
  }, []);

  return (
    <>
      <Mobile>
        <MobileView />
      </Mobile>
      <Desktop>
        <DesktopView loading={loadingBanner} />
      </Desktop>
    </>
  );
});

export default DashboardTab;
