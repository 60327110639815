import React from "react";
import "./locales/i18n";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import "./index.css";
import { HelmetProvider } from 'react-helmet-async';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/configureStore";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const persister = persistStore(store);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persister}>
      {/* <React.StrictMode> */}
      <HelmetProvider>
        <App />
      </HelmetProvider>
      {/* </React.StrictMode> */}
    </PersistGate>
  </Provider>
);
// if ("serviceWorker" in navigator) {
//   serviceWorkerRegistration.register({
//     onUpdate(registration) {
//       if (registration && registration.waiting) {
//         registration.waiting.postMessage({ type: "SKIP_WAITING" });
//       }
//       // window.location.reload();
//     },
//     onSuccess(registration) {
//       // window.location.reload();
//     },
//   });
// }

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
