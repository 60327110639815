import "./styles.scss";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "antd";
import AppButton from "src/components/AppButton";
import AppTextField from "src/components/Form/AppTextField";
import AppDatePicker from "src/components/Form/AppDatePicker";
import { AppTextArea } from "@components/Form/AppTextArea/index";
import { DefaultOptionType, LabeledValue } from "antd/lib/select";
import { useDispatch, useSelector } from "react-redux";
import AppStore, { AppDispatch, RootState } from "src/store/configureStore";
import moment from "moment";
import AppSelectCategory from "@components/AppSelectCategory/index";
import { useTranslation } from "react-i18next";
import AppSelect from "src/components/Form/AppSelect";
import AppSelectYear from "src/components/Form/AppYearSelect";
import { setAppLoading, setError } from "src/store/State";
import {
  checkZipCode,
  getDistricts,
  getZipCodes,
} from "src/services/API/SignUp";
import { CHARACTER_SPECIALS } from "src/configs";
import { ZipCodeValue } from "src/pages/sign-up/model";
import { getListCategory as getListCategoryAction } from "@store/Product";
import { SellingState } from "@pages/selling/selling-page";
import CommonService from "../../../services/Common/index";

interface Props {
  onConfirm: any;
  screenStatus: number;
}
const AddInfoScreen: FC<Props> = ({ ...props }) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const stateListProvinces = useSelector(
    (state: RootState) => state.GlobalReducer.provinces
  );

  const [form] = Form.useForm();
  const [cityValue, setCityValue] = useState<string>(
    form.getFieldValue("province")
  );
  const [districts, setDistricts] = useState<string[]>([]);
  const [isCanCallZipCode, setIsCanCallZipCode] = useState<boolean>(false);
  const [zipCodeListByDistrict, setZipCodeListByDistrict] = useState<
    ZipCodeValue[]
  >([]);
  const [zipCodeListByProvince, setZipCodeListByProvince] = useState<
    ZipCodeValue[]
  >([]);
  const currentZipCode = useRef("");
  const [isExistZipCode, setIsExistZipCode] = useState<boolean>(true);

  function filterZipCodeByKey(data: ZipCodeValue[], key: any) {
    const seen: any = new Set();
    return data.filter((item) => {
      const k: any = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  const handleCheckZipCodeExists = async (zipCode: string) => {
    try {
      await checkZipCode(zipCode);
    } catch (error) {
      setIsExistZipCode(false);
    }
  };

  const handleZipCodeChange = async () => {
    const zipCode = `${form.getFieldValue("zipCode") || ""}`;
    if (
      zipCode.length === 7 &&
      isCanCallZipCode &&
      zipCode !== currentZipCode.current
    ) {
      dispatch(setAppLoading(true));
      try {
        const responseZipCodes = await getZipCodes(zipCode);
        const zipCodeRes = responseZipCodes.data.result;
        currentZipCode.current = zipCode as string;
        if (zipCodeRes.length === 0) {
          handleCheckZipCodeExists(zipCode);
          return;
        }
        setIsExistZipCode(true);
        const filterZipCodeByDistrict = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.district
        );
        setZipCodeListByDistrict(filterZipCodeByDistrict);
        const filterZipCodeByProvince = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.province
        );
        setZipCodeListByProvince(filterZipCodeByProvince);

        if (zipCodeRes.length === 1) {
          form.setFields([
            {
              name: "province",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: zipCodeRes[0].district,
              touched: true,
            },
            {
              name: "street",
              value: zipCodeRes[0].street,
              touched: true,
            },
          ]);
          await form.validateFields(["province", "district"]);
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length > 1
        ) {
          form.setFields([
            {
              name: "province",
              value: zipCodeRes ? zipCodeRes[0].province : "",
              touched: true,
            },
            {
              name: "district",
              value: "",
              touched: true,
            },
            {
              name: "street",
              value: "",
              touched: true,
            },
          ]);
          await form.validateFields(["province"]);
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length === 1
        ) {
          form.setFields([
            {
              name: "province",
              value: zipCodeRes ? zipCodeRes[0].province : "",
              touched: true,
            },
            {
              name: "district",
              value: zipCodeRes ? filterZipCodeByDistrict[0].district : "",
              touched: true,
            },
            {
              name: "street",
              value: "",
              touched: true,
            },
          ]);
          await form.validateFields(["province", "district"]);
        } else if (zipCodeRes.length === 0) {
          form.setFieldsValue({ province: "", district: "" });
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setAppLoading(false));
      }
    } else if (zipCode.length !== 7) {
      form.setFieldsValue({ province: "", district: "", street: "" });
      await form.validateFields(["city", "district", "street"]);
      setZipCodeListByProvince([]);
      setZipCodeListByDistrict([]);
      currentZipCode.current = zipCode;
    }
  };

  const handleGetDistricts = async () => {
    try {
      dispatch(setAppLoading(true));
      const response = await getDistricts(cityValue);
      const zipCodeInfo = response.data.result;
      setDistricts(
        zipCodeInfo
          .map((item: any) => item.district)
          .filter((district: null) => district !== null)
      );
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    if (cityValue) {
      handleGetDistricts();
    }
  }, [cityValue]);

  const checkLength = (_: any, value: string) => {
    const length = value?.length;
    const zipCodeLength = 7;
    if (length !== zipCodeLength) {
      return Promise.reject(
        translate("editProduct.form.validate.zipCode.format")
      );
    }
    return Promise.resolve();
  };

  const onFinish = () => {
    if (!isExistZipCode) {
      dispatch(setError({ message: "M0198", title: "common.modal.userError" }));
      return;
    }
    props.onConfirm(form.getFieldsValue());
  };

  const handleBlurKeyword = () => {
    const value = form.getFieldValue("keyword");
    const handledValue = value.replace(/  +/g, "");
    form.setFieldsValue({ keyword: handledValue });
    form.validateFields(["keyword"]);
  };

  useEffect(() => {
    if (props.screenStatus === SellingState.ADD_INFO) {
      AppStore.dispatch(getListCategoryAction());
    }
  }, [props.screenStatus]);

  return (
    <div className="add-screen-container">
      <div className="screen-title">
        <div className="text-note">
          {translate("public.product.step2.text1")}
        </div>
        <div>
          <span className="color-primary">*</span>{" "}
          <span className="text-note">
            {translate("public.product.step2.text2")}
          </span>
        </div>
      </div>
      <div className="bold text-body-1">商品情報</div>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col
            xs={24}
            // onClick={() => {
            //   setShowCategory(true);
            // }}
          >
            <AppSelectCategory
              onChange={(value) => {
                form.setFieldsValue({
                  category: value,
                });
              }}
              className="point-event-none"
              label={translate("product.detail.category")}
              options={[]}
              name="category"
              rules={[
                {
                  required: true,
                  message: translate("serverErrorMessage.M0055"),
                },
              ]}
            ></AppSelectCategory>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppTextField
              label={translate("product.detail.maker")}
              name="maker"
              rules={[
                { required: true, message: translate("error.maker.required") },
              ]}
            ></AppTextField>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppTextField
              label={translate("product.detail.title")}
              name="title"
              rules={[
                { required: true, message: translate("error.title.required") },
              ]}
            ></AppTextField>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            {/* <AppDatePicker
              inputReadOnly
              allowClear={false}
              disabledDate={(d) =>
                !d || d.isAfter(moment()) || d.isSameOrBefore("1980-01-01")
              }
              label={translate("editProduct.form.modelYear")}
              name="year"
              picker="year"
              format="YYYY"
              rules={[
                { required: true, message: translate("error.year.required") },
              ]}
            ></AppDatePicker> */}

            <AppSelectYear
              name="year"
              label={translate("editProduct.form.modelYear")}
              placeholder="年"
              allowClear
              rules={
                [
                  // { required: true, message: translate("error.year.required") },
                ]
              }
              extra="年式不明の場合は空白で登録して下さい。"
            ></AppSelectYear>
          </Col>
        </Row>

        <Row gutter={[8, 8]} className="row-with-des">
          <Col xs={24}>
            <AppTextField
              name="keyword"
              label={translate("editProduct.form.keyword")}
              rules={[
                {
                  required: true,
                  message: translate("error.keyword.required"),
                },
              ]}
              onBlur={handleBlurKeyword}
            />
          </Col>
          <div className="text-body-4 color-body">
            複数のキーワードを設定する場合には、空白で区切って登録して下さい。
          </div>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppTextArea
              label={translate("editProduct.form.comment")}
              name="comment"
              maxLength={1000}
            ></AppTextArea>
          </Col>
        </Row>

        <div className="post-code bold text-body-1">現在の保管場所</div>

        <Row gutter={[8, 8]} className="row-with-des">
          <Col xs={24}>
            <AppTextField
              rules={[
                {
                  required: true,
                  message: translate("error.zipCode.required"),
                },
                {
                  validator: checkLength,
                },
              ]}
              label={translate("signup.form.zipCode")}
              name="zipCode"
              onBlur={handleZipCodeChange}
              type="number"
              onlyInputNumber="true"
              pattern="[0-9]*"
              formgroup={form}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  (event.target as any).blur();
                  event.stopPropagation();
                  event.preventDefault();
                }

                if (CHARACTER_SPECIALS.includes(event.key)) {
                  event.preventDefault();
                }
              }}
              onWheel={(e) => (e.target as any).blur()}
              onKeyDown={(event) => {
                if (
                  event.key === "e" ||
                  event.key === "E" ||
                  event.key === "ArrowUp" ||
                  event.key === "ArrowDown"
                ) {
                  event.preventDefault();
                }
              }}
              onPaste={(e) => {
                e.preventDefault();
                if (
                  CHARACTER_SPECIALS.some((char) =>
                    e.clipboardData.getData("text").includes(char)
                  )
                ) {
                  return false;
                }
                form.setFieldsValue({
                  zipCode: e.clipboardData.getData("text"),
                });
              }}
              onChange={(e) =>
                setIsCanCallZipCode(!!(e.target.value.length === 7))
              }
            ></AppTextField>
          </Col>
          <div className="text-body-4 color-body">
            郵便番号は"ー"(ハイフン)抜きで必ず数字で入力して下さい。
          </div>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppSelect
              label={translate("editProduct.form.city")}
              options={
                zipCodeListByProvince.length > 0
                  ? zipCodeListByProvince.map((zipCode) => ({
                      label: zipCode.province,
                      value: zipCode.province,
                    }))
                  : stateListProvinces.map((province) => ({
                      label: province,
                      value: province,
                    }))
              }
              name="province"
              rules={[
                {
                  required: true,
                  message: translate("error.province.required"),
                },
              ]}
              onChange={(value) => {
                setCityValue(value);
                form.setFieldsValue({ district: null });
              }}
            ></AppSelect>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <AppSelect
              rules={[
                {
                  required: true,
                  message: translate("serverErrorMessage.M0016"),
                },
              ]}
              options={
                zipCodeListByDistrict.length > 0
                  ? zipCodeListByDistrict.map((zipCode) => ({
                      label: zipCode.district,
                      value: zipCode.district,
                    }))
                  : districts.map((district) => ({
                      label: district,
                      value: district,
                    }))
              }
              label={translate("editProduct.form.district")}
              name="district"
            ></AppSelect>
          </Col>
        </Row>

        <Row gutter={[8, 8]} className="row-with-des">
          <Col xs={24}>
            <AppTextField
                rules={[{ required: true, message: "地名番地・建物名は必須です。" }]}
                label={translate("product.step2.storageLocation")}
                name="street"
                formgroup={form}
            ></AppTextField>
          </Col>
        </Row>

        <div className="button-container">
          <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
            {() => (
              <AppButton
                buttontype="primary"
                htmlType="submit"
                onClick={() => {
                  CommonService.scrollToError();
                }}
              >
                {translate("public.product.step2.next")}
              </AppButton>
            )}
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default AddInfoScreen;
