import { Col, Form, Row } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AppTextField from "@components/Form/AppTextField";
import { CHARACTER_SPECIALS, DATE_FORMAT, PATH_NAME } from "src/configs";
import "./styles.scss";
import { cloneDeep, get, remove } from "lodash";
import moment from "moment";
import ProductService from "@service/API/Product";
import { LabeledValue } from "antd/lib/select";
import AppDatePicker from "@components/AppDatePicker";
import AppNavigation from "@components/AppNavigation";
import AppTextArea from "@components/Form/AppTextArea";
import AppSelect from "@components/Form/AppSelect";
import AppRadio from "@components/Form/AppRadio";
import AppCheckbox from "@components/Form/AppCheckbox/index";
import { AppDispatch, RootState } from "@store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { ProductItem, ProductPublicStatus, ProductTransactionStatus } from "@models/product.model";
import { clearProduct, getProduct } from "@store/Product";
import AppButton from "@components/AppButton";
import AppModal from "@components/AppModal";
import { ReactComponent as Success } from "@components/Icons/success.svg";
import { FileType, MessageType } from "@models/common.model";
import MediaChooser from "src/components/AppChooseMedia";
import AppSelectCategory from "src/components/AppSelectCategory";
import AppSliderMedia, { MediaSlider } from "src/components/AppSliderMedia";
import { setAppLoading, setError } from "@store/State";
import { v4 as uuidv4 } from "uuid";
import { Desktop, Mobile } from "src/layouts/break-point";
import { ReactComponent as Information } from "@components/Icons/information.svg";
import AppInputNumber from "@components/Form/AppInputNumber";
import { usePrompt } from "src/hooks/useBlocker";
import { ReactComponent as Warning } from "@components/Icons/warning.svg";
import AppSelectYear from "src/components/Form/AppYearSelect";
import { getDistricts, getZipCodes } from "src/services/API/SignUp";
import { ReactComponent as ArrowLeft2 } from "@components/Icons/arrow-left-2.svg";
import CommonService from "src/services/Common";
import { Collection } from "@models/chat.model";
import { db } from "src/firebase";
import ChatService from "@service/API/Chat";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getTimeZone } from "src/helper";
import { ZipCodeValue } from "../sign-up/model";
import useScrollTop from "../../hooks/useScrollTop";

export default function EditProductDetail() {
  useScrollTop();
  const [listCategoryOption, setListCategoryOption] = useState<LabeledValue[]>(
    []
  );

  const savedMedia = useRef<MediaSlider[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchParams, _] = useSearchParams("id");
  const [medias, setMedias] = useState<MediaSlider[]>([]);
  const [form] = Form.useForm();
  const id = searchParams.get("id");
  const dispatch = useDispatch<AppDispatch>();
  const product: ProductItem = useSelector(
    (state: RootState) => state.ProductReducer.productDetail
  );
  const [disabledEstimationDate, setDisabledEstimationDate] =
    useState<boolean>(false);

  const { provinces, zipCodes } = useSelector(
    (state: RootState) => state.GlobalReducer
  );
  const [cityValue, setCityValue] = useState<string>(product.city);
  const [districts, setDistricts] = useState<string[]>([]);
  const [warningEditModalVisible, setWarningEditModalVisible] =
    useState<boolean>(false);
  const [successEditModalVisible, setSuccessEditModalVisible] =
    useState<boolean>(false);
  const [modalWarningVisible, setModalWarningVisible] =
    useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const routerTx = useRef();
  const [isCanCallZipCode, setIsCanCallZipCode] = useState<boolean>(false);
  const [zipCodeListByDistrict, setZipCodeListByDistrict] = useState<
    ZipCodeValue[]
  >([]);
  const [zipCodeListByProvince, setZipCodeListByProvince] = useState<
    ZipCodeValue[]
  >([]);
  const [type, setType] = useState<string>("");
  const [isBought, setIsBought] = useState<boolean>(false);

  const currentZipCode = useRef("");

  const blocker = useCallback((tx: any) => {
    routerTx.current = tx;
    setModalWarningVisible(true);
  }, []);

  usePrompt(blocker, isDirty);

  const radioList: LabeledValue[] = [
    {
      value: 2,
      label: t("editProduct.form.buyer"),
    },
    {
      value: 1,
      label: t("editProduct.form.seller"),
    },
  ];

  useEffect(() => {
    dispatch(clearProduct());
  }, []);

  const handleGetDistricts = async () => {
    try {
      dispatch(setAppLoading(true));
      const response = await getDistricts(cityValue || product?.city);
      const zipCodeInfo = response.data.result;
      setDistricts(
        zipCodeInfo
          .map((item: any) => item.district)
          .filter((district: null) => district !== null)
      );
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
    }
  };

  useEffect(() => {
    if (id && product.id) {
      const clonedProduct = { ...product };
      
      if (clonedProduct.canPublish) {
        clonedProduct.endDate = "未公開";
        clonedProduct.publicDate = "未公開";
      } else if (!clonedProduct.canPublish) {
        clonedProduct.endDate = moment(clonedProduct.endDate, DATE_FORMAT).format("YYYY年MM月DD日");
        clonedProduct.publicDate = moment(clonedProduct.publicDate, DATE_FORMAT).format("YYYY年MM月DD日");
      }

      // @ts-ignore
      clonedProduct.isAllowOnlineTours = clonedProduct.isAllowOnlineTours
        ? [true]
        : [];
      // @ts-ignore
      clonedProduct.isNeedTakeBackQuote = clonedProduct.isNeedTakeBackQuote
        ? [true]
        : [];

      if (clonedProduct.estimationDate) {
        clonedProduct.estimationDate = moment(
          clonedProduct.estimationDate,
          DATE_FORMAT
        );
      }

      // if (clonedProduct.yearOfManufacture) {
      //   clonedProduct.yearOfManufacture = clonedProduct.yearOfManufacture;
      // }

      // @ts-ignore
      clonedProduct.isPostVideoToYoutube = clonedProduct.isPostVideoToYoutube
        ? [true]
        : [];

      form.setFieldsValue(clonedProduct);
    }

    if (id && !product.id) {
      dispatch(getProduct(id));
    }

    setDisabledEstimationDate(!!product.isNeedTakeBackQuote);
  }, [id, product]);

  useEffect(() => {
    if (product && product.productMedias) {
      const convertedMedias = product.productMedias.map((media) => ({
        src: media.url,
        id: media.id,
        type: media.type === FileType.IMAGE ? "camera" : "video",
      }));
      setMedias(convertedMedias);
      savedMedia.current = convertedMedias;
    }
  }, [product]);

  function removeMediaItem(index: number) {
    remove(medias, {
      id: index,
    });

    savedMedia.current = cloneDeep(medias);

    setMedias(cloneDeep(medias));
    setIsDirty(true);
  }

  const currentPathname = useSelector(
    (state: RootState) => state.GlobalReducer.currentPathname
  );

  const location = useLocation();

  function handleBack() {
    if (!currentPathname?.length || currentPathname.length === 1) {
      navigate("/");
    } else {
      navigate(-1);
    }
  }

  function addToMedia(files: any[], listUrl: string[], type: string) {
    let newList = [...medias];

    for (let i = 0; i < files.length; ++i) {
      const url = listUrl[i];
      const newMedia: MediaSlider = {
        type: url.indexOf("upload-images") > 0 ? "camera" : "video",
        src: url,
        id: uuidv4(),
      };
      newList = [newMedia, ...newList];
    }
    setMedias([...newList]);
    setIsDirty(true);
    setType(type);
  }

  function addToMediaFromApp(listUrl: string[]) {
    let newList = [...savedMedia.current];

    for (let i = 0; i < listUrl.length; ++i) {
      const url = listUrl[i];
      const newMedia: MediaSlider = {
        type: url.indexOf("upload-images") > 0 ? "camera" : "video",
        src: listUrl[i],
        id: uuidv4(),
      };
      newList = [newMedia, ...newList];
    }
    savedMedia.current = newList;
    setMedias(newList);
    setIsDirty(true);
    setType(type);
  }

  const checkCanEditItem = async () => {
    if (id) {
      dispatch(setAppLoading(true));
      try {
        const res = await ProductService.getProduct(id);
        const status = res.data.result.orderStatus;
        if (
          [
            ProductTransactionStatus.IN_PURCHASE,
            ProductTransactionStatus.IN_REQUEST,
            ProductTransactionStatus.IN_CONTACT,
            ProductTransactionStatus.IN_CONFIRM,
            ProductTransactionStatus.CANCEL,
          ].some((item) => item === status) || !status
        ) {
          setWarningEditModalVisible(true);
        } else setIsBought(true);
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setAppLoading(false));
      }
    }
  };

  async function onFinish(values: ProductItem) {
    const listType = medias.map((x) => x.type);
    const listImage = listType.filter((f) => f === "camera");

    // validate at least 1 image
    if (get(listImage, "length") <= 0) {
      dispatch(setError({ message: "C001" }));
      return false;
    }

    // validate at max 10 image
    if (get(listImage, "length") > 10) {
      dispatch(setError({ message: "C004" }));
      return false;
    }

    const listVideo = listType.filter((f) => f === "video");
    // validate at max 1 video
    if (get(listVideo, "length") > 1) {
      dispatch(setError({ message: "C004" }));
      return false;
    }
    checkCanEditItem();
  }

  function onChangePosition(listMedia: MediaSlider[]) {
    setMedias(cloneDeep(listMedia));
    savedMedia.current = cloneDeep(listMedia);
  }

  const handleSaveProduct = async () => {
    const values: ProductItem = form.getFieldsValue();
    const formData = {
      ...values,
      id: product.id,
      endDate: moment(values.endDate)
        .set({ hour: moment().hour() })
        .toISOString(),
      // estimationDate: moment(values.estimationDate)
      //   .set({ hour: moment().hour() })
      //   .startOf("day").add("hour", getTimeZone()).toISOString(),
      estimationDate: "",
      yearOfManufacture: values.yearOfManufacture,
      medias: medias.map((media: MediaSlider) => media.src),
      isPostVideoToYoutube: !!(values.isPostVideoToYoutube as any)?.length,
      isAllowOnlineTours: !!(values.isAllowOnlineTours as any)?.length,
      isNeedTakeBackQuote: false,
    };
    try {
      dispatch(setAppLoading(true));
      const response = await ProductService.updateProduct(formData);
      const updatedProduct = response.data.result;
      const roomIndexes: Array<string> = [];
      const q = query(collection(db, Collection.ROOMS), where("productId", "==", updatedProduct.id));
      const snapshot = await getDocs(q);
      snapshot.forEach(doc => roomIndexes.push(doc.id));
      if (roomIndexes.length) {
        await Promise.all(roomIndexes.map(roomIndex => ChatService.updateRoomInformation(roomIndex, { roomTitle: updatedProduct.title, roomImage: updatedProduct?.productMedias?.filter((media: any) => media.type === FileType.IMAGE)?.[0]?.url || "" })));
      }
      dispatch(getProduct(product.id as string));
      setSuccessEditModalVisible(true);
      setIsDirty(false);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(setAppLoading(false));
      setWarningEditModalVisible(false);
    }
  };

  function filterZipCodeByKey(data: ZipCodeValue[], key: any) {
    const seen: any = new Set();
    return data.filter((item) => {
      const k: any = key(item);
      return seen.has(k) ? false : seen.add(k);
    });
  }

  const handleZipCodeChange = async () => {
    const zipCode = `${form.getFieldValue("zipCode") || ""}`;
    if (
      zipCode.length === 7 &&
      isCanCallZipCode &&
      zipCode !== currentZipCode.current
    ) {
      dispatch(setAppLoading(true));
      try {
        const responseZipCodes = await getZipCodes(zipCode);
        const zipCodeRes = responseZipCodes.data.result;
        currentZipCode.current = zipCode as string;
        const filterZipCodeByDistrict = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.district
        );
        setZipCodeListByDistrict(filterZipCodeByDistrict);
        const filterZipCodeByProvince = filterZipCodeByKey(
          zipCodeRes,
          (zipCode: ZipCodeValue) => zipCode.province
        );
        setZipCodeListByProvince(filterZipCodeByProvince);

        if (zipCodeRes.length === 1) {
          form.setFields([
            {
              name: "city",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: zipCodeRes[0].district,
              touched: true,
            },
            {
              name: "street",
              value: zipCodeRes[0].street,
              touched: true,
            },
          ]);
          await form.validateFields(["city", "district"]);
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length > 1
        ) {
          form.setFields([
            {
              name: "city",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: "",
              touched: true,
            },
            {
              name: "street",
              value: "",
              touched: true,
            },
          ]);
          await form.validateFields(["city"]);
        } else if (
          zipCodeRes.length > 1 &&
          filterZipCodeByDistrict.length === 1
        ) {
          form.setFields([
            {
              name: "city",
              value: zipCodeRes[0].province,
              touched: true,
            },
            {
              name: "district",
              value: filterZipCodeByDistrict[0].district,
              touched: true,
            },
            {
              name: "street",
              value: "",
              touched: true,
            },
          ]);
          await form.validateFields(["city", "district"]);
        } else if (zipCodeRes.length === 0) {
          form.setFieldsValue({ city: "", district: "" });
        }
      } catch (e) {
        console.log(e);
      } finally {
        dispatch(setAppLoading(false));
        setIsCanCallZipCode(false);
      }
    } else if (zipCode.length !== 7) {
      form.setFieldsValue({ city: "", district: "", street: "" });
      await form.validateFields(["city", "district", "street"]);
      setZipCodeListByProvince([]);
      setZipCodeListByDistrict([]);
      currentZipCode.current = zipCode;
    }
  };

  useEffect(() => {
    if (product.city !== undefined) {
      handleGetDistricts();
    }
  }, [cityValue]);

  function handleMessageFromApp(event: any) {
    if (!event || !event.data) {
      return;
    }
    try {
      const message = JSON.parse(event.data);
      switch (message.type) {
        case MessageType.LOADING:
          dispatch(setAppLoading(message.data));
          break;

        case MessageType.MEDIA:
          addToMediaFromApp(message.data);
          break;
        case MessageType.ERROR:
          if (message.data) {
            dispatch(setError(message.data || {}));
          }
          break;

        default:
          break;
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  useEffect(() => {
    window.removeEventListener("message", handleMessageFromApp, false); // Fails
    window.addEventListener("message", (ev) => handleMessageFromApp(ev), false);
    document.addEventListener(
      "message",
      (ev) => handleMessageFromApp(ev),
      false
    );
    return () => {
      window.removeEventListener("message", handleMessageFromApp, false);
      document.removeEventListener(
        "message",
        (ev) => handleMessageFromApp(ev),
        false
      );
    };
  }, []);

  useEffect(() => {
    if (
      medias.length > 0 &&
      medias.filter((f) => f.type === "video").length === 0
    ) {
      form.setFields([
        {
          name: "isPostVideoToYoutube",
          value: [],
        },
      ]);
    }
  }, [medias]);

  useEffect(() => {
    setDisabledEstimationDate(!product.isNeedTakeBackQuote as boolean);
  }, [product]);

  const validateDatePicker = (object: any, value: string) => {
    const date = form?.getFieldValue(object.field);
    const errorMessage = t(
      `editProduct.form.validate.${
        object.field === "endDate" ? "postingEndDate" : "desiredDate"
      }.wrong`
    );
    if (
      moment(moment().format(DATE_FORMAT)).isAfter(
        moment(date).format(DATE_FORMAT)
      ) && !disabledEstimationDate
    ) {
      return Promise.reject(errorMessage);
    }
    return Promise.resolve();
  };

  const validatePrice = (_: any, value: number) => {
    const isTrading = product?.orderStatus;
    const currentPrice = product.price;
    if (isTrading) {
      if (currentPrice < value) {
        return Promise.reject(t("editProduct.form.validate.price.wrong"));
      }
    }
    if (value === 0) return Promise.reject('価格は必須です。');
    return Promise.resolve();
  };

  const onValuesChange = () => {
    setIsDirty(true);
  };

  const checkLength = (_: any, value: string) => {
    const length = value?.length;
    const zipCodeLength = 7;
    if (length !== zipCodeLength) {
      return Promise.reject(t("editProduct.form.validate.zipCode.format"));
    }
    return Promise.resolve();
  };

  const handleBlurKeyword = () => {
    const value = form.getFieldValue("keyword");
    const handledValue = value.replace(/  +/g, "");
    form.setFieldsValue({ keyword: handledValue });
    form.validateFields(["keyword"]);
  };

  useEffect(() => {
    try {
      const message = {
        type: "UPLOAD_MEDIA",
        data: type,
        listMedia: medias,
      };
      (window as any).ReactNativeWebView?.postMessage(JSON.stringify(message));
    } catch (e) {
      console.log(e);
    }
  }, [medias]);


  const guidelineDayOfEndDate = () => (
    <>
      <div>商品の公開は最大120日まで設定できます。</div>
      <div>商品の公開をさらに延長する場合は、再度公開して下さい。</div>
    </>
  );

  return (
    <div className="edit-product-container">
      <AppModal
        visible={warningEditModalVisible}
        title={t("editProduct.warningEditModal.title")}
        content={t("editProduct.warningEditModal.content")}
        okText={t("editProduct.warningEditModal.okText")}
        cancelText={t("editProduct.warningEditModal.cancelText")}
        onCancel={() => setWarningEditModalVisible(false)}
        onOk={handleSaveProduct}
      />

      <AppModal
        icon={<Success />}
        visible={successEditModalVisible}
        title={t("editProduct.successEditModal.title")}
        content={t("editProduct.successEditModal.content")}
        okText={t("editProduct.successEditModal.okText")}
        onOk={() => {
          setSuccessEditModalVisible(false);
          // navigate(`/product-detail/${id}`);
          handleBack();
        }}
      />

      <AppModal
        visible={modalWarningVisible}
        icon={<Warning />}
        title="登録中の商品は破棄されます。よろしいですか？"
        content=""
        okText="はい"
        cancelText="いいえ"
        onCancel={() => setModalWarningVisible(false)}
        onOk={() => {
          (routerTx.current as any).retry();
        }}
      />

      <AppModal
        icon={<Warning />}
        visible={isBought}
        title="エラーが発生しました。"
        content="商品は既に購入確定されています。"
        okText="OK"
        onOk={() => {
          setIsBought(false);
        }}
      />  

      <AppNavigation
        title={t("editProduct.pageTitle")}
        // route={`/product-detail/${id}`}
        onClick={() => {
          handleBack();
        }}
      />

      <Row gutter={[32, 32]} justify="center" className="row-mobile">
        <Col xs={24} xl={14} className="col-s-parent">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
            <div className="edit-product-container__page-title">
              <ArrowLeft2
                onClick={() => {
                  handleBack();
                }}
              />

              {t("editProduct.pageTitle")}
            </div>
            <div className="media-container">
              <div className="media-container__slider">
                <div>
                  <div className="media-container__slider-title">
                    {t("editProduct.mediaChooser.title")}
                  </div>
                  <div className="media-container__slider-description">
                    <Information style={{ marginRight: "8px" }} />
                    {t("editProduct.mediaChooser.description")}
                  </div>
                </div>
                <AppSliderMedia
                  listMedia={medias}
                  onRemoveItem={removeMediaItem}
                  onChangePosition={onChangePosition}
                />
              </div>

              <div className="media-container__chooser">
                <Row gutter={[32, 32]}>
                  <Col xs={24} xl={9}>
                    <MediaChooser
                      listMedia={savedMedia.current}
                      useCase="EDIT"
                      label={t("editProduct.media.gallery")}
                      type="gallery"
                      onSelect={(
                        files: any[],
                        listUrl: string[],
                        type: string
                      ) => addToMedia(files, listUrl, type)}
                      productId={id as string}
                    />
                  </Col>
                  <Mobile>
                    <Col xs={12}>
                      <MediaChooser
                        useCase="EDIT"
                        label={t("editProduct.media.video")}
                        type="video"
                        listMedia={savedMedia.current}
                        onSelect={(
                          files: any[],
                          listUrl: string[],
                          type: string
                        ) => addToMedia(files, listUrl, type)}
                        productId={id as string}
                      />
                    </Col>

                    <Col xs={12}>
                      <MediaChooser
                        listMedia={savedMedia.current}
                        useCase="EDIT"
                        label={t("editProduct.media.image")}
                        type="image"
                        onSelect={(
                          files: any[],
                          listUrl: string[],
                          type: string
                        ) => addToMedia(files, listUrl, type)}
                        productId={id as string}
                      />
                    </Col>
                  </Mobile>

                  {/* <Col xs={24}>
                    {medias.some((media) => media.type === "video") && (
                      <AppCheckbox
                        disabled={
                          !medias.some((media) => media.type === "video")
                        }
                        type="default"
                        name="isPostVideoToYoutube"
                        options={[
                          {
                            label: t("editProduct.postToYoutube"),
                            value: true,
                          },
                        ]}
                      />
                    )}
                  </Col> */}

                  <Desktop>
                    <div
                      className="edit-product-container__line"
                      style={{ marginTop: 0 }}
                    >
                      &nbsp;
                    </div>
                  </Desktop>
                </Row>
              </div>
            </div>

            <div className="content-container">
              <div className="content-container__explain">
                {t("editProduct.explain")}
              </div>
              <div className="content-container__title text-body-1 text-title bold">
                {t("editProduct.form.productInformation")}
              </div>
              <div className="content-container__body">
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppSelectCategory
                      onChange={(value) => {
                        form.setFieldsValue({
                          categoryId: value,
                        });
                      }}
                      className="point-event-none"
                      label="カテゴリ"
                      options={listCategoryOption}
                      name="categoryId"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "editProduct.form.validate.category.required"
                          ),
                        },
                      ]}
                    ></AppSelectCategory>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppTextField
                      name="maker"
                      label={t("editProduct.form.producer")}
                      placeholder=""
                      rules={[
                        {
                          required: true,
                          message: t(
                            "editProduct.form.validate.maker.required"
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppTextField
                      name="title"
                      label={t("editProduct.form.productName")}
                      placeholder=""
                      formgroup={form}
                      rules={[
                        {
                          required: true,
                          message: t(
                            "editProduct.form.validate.productName.required"
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppSelectYear
                      allowClear
                      name="yearOfManufacture"
                      label={t("editProduct.form.modelYear")}
                      placeholder="年"
                      rules={
                        [
                          // {
                          //   required: true,
                          //   message: t("editProduct.form.validate.year.required"),
                          // },
                        ]
                      }
                      extra="年式不明の場合は空白で登録して下さい。"
                    ></AppSelectYear>
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppTextField
                      name="keyword"
                      label={t("editProduct.form.keyword")}
                      placeholder=""
                      rules={[
                        {
                          required: true,
                          message: t(
                            "editProduct.form.validate.searchKeyword.required"
                          ),
                        },
                      ]}
                      onBlur={handleBlurKeyword}
                      extra="複数のキーワードを設定する場合には、空白で区切って登録して下さい。"
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppTextArea
                      name="comment"
                      label={t("editProduct.form.comment")}
                      placeholder=""
                      formgroup={form}
                      maxLength={1000}
                    />
                  </Col>
                </Row>

                <div className="edit-product-container__line">&nbsp;</div>

                <div
                  style={{
                    paddingBottom: "16px",
                    color: "#333",
                    fontSize: "17px",
                    fontWeight: 700,
                  }}
                >
                  現在の保管場所
                </div>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppTextField
                      formgroup={form}
                      onBlur={handleZipCodeChange}
                      name="zipCode"
                      label={t("signup.form.zipCode")}
                      placeholder=""
                      onlyInputNumber="true"
                      type="number"
                      pattern="[0-9]*"
                      extra={t("signup.form.validate.zipCode.guideline", {
                        params: '"ー"',
                      })}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          (event.target as any).blur();
                          event.stopPropagation();
                          event.preventDefault();
                        }

                        if (CHARACTER_SPECIALS.includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      rules={[
                        {
                          required: true,
                          message: t("signup.form.validate.zipCode.required"),
                        },
                        {
                          validator: checkLength,
                        },
                      ]}
                      onPaste={(e) => {
                        e.preventDefault();
                        if (
                          CHARACTER_SPECIALS.some((char) =>
                            e.clipboardData.getData("text").includes(char)
                          )
                        ) {
                          return false;
                        }
                        form.setFieldsValue({
                          zipCode: e.clipboardData.getData("text"),
                        });
                      }}
                      onWheel={(e) => (e.target as any).blur()}
                      onKeyDown={(event) => {
                        if (
                          event.key === "e" ||
                          event.key === "E" ||
                          event.key === "ArrowUp" ||
                          event.key === "ArrowDown"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      onChange={(e) =>
                        setIsCanCallZipCode(!!(e.target.value.length === 7))
                      }
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppSelect
                      name="city"
                      label={t("editProduct.form.city")}
                      placeholder=""
                      options={
                        zipCodeListByProvince.length > 0
                          ? zipCodeListByProvince.map((zipCode) => ({
                              label: zipCode.province,
                              value: zipCode.province,
                            }))
                          : provinces.map((province) => ({
                              label: province,
                              value: province,
                            }))
                      }
                      rules={[
                        {
                          required: true,
                          message: t(
                            "editProduct.form.validate.province.required"
                          ),
                        },
                      ]}
                      onChange={(value) => {
                        setCityValue(value);
                        form.setFieldsValue({ district: null });
                      }}
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppSelect
                      name="district"
                      label={t("editProduct.form.district")}
                      placeholder=""
                      rules={[
                        {
                          required: true,
                          message: t(
                            "editProduct.form.validate.district.required"
                          ),
                        },
                      ]}
                      options={
                        zipCodeListByDistrict.length > 0
                          ? zipCodeListByDistrict.map((zipCode) => ({
                              label: zipCode.district,
                              value: zipCode.district,
                            }))
                          : districts.map((district) => ({
                              label: district,
                              value: district,
                            }))
                      }
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]} className="row-with-des">
                  <Col xs={24}>
                    <AppTextField
                      rules={[{ required: true, message: "地名番地・建物名は必須です。" }]}
                      label={t("product.step2.storageLocation")}
                      name="street"
                      formgroup={form}
                    ></AppTextField>
                  </Col>
                </Row>

                <div className="edit-product-container__line">&nbsp;</div>

                <div className="content-container__title text-body-1 text-title bold">
                  {t("editProduct.form.productCondition")}
                </div>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppInputNumber
                      name="price"
                      prefix="円"
                      maxLength={11}
                      label={t("editProduct.form.askingPrice")}
                      placeholder=""
                      formatter={(value: number) =>
                        new Intl.NumberFormat("ja-JP").format(value)
                      }
                      rules={[
                        {
                          required: true,
                          message: t(
                            "editProduct.form.validate.price.required"
                          ),
                        },
                        {
                          validator: validatePrice,
                        },
                      ]}
                      extra={product?.orderStatus && "取引中(購入依頼以降)の価格変更は値下げのみ可能となります。"}
                    />
                  </Col>
                </Row>

                {/* <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppDatePicker
                      values={
                        form.getFieldValue("endDate")
                          ? form.getFieldValue("endDate")
                          : undefined
                      }
                      form={form}
                      name="endDate"
                      label={t("editProduct.form.postingEndDate")}
                      rules={[
                        {
                          required: true,
                          message: t(
                            "editProduct.form.validate.postingEndDate.required"
                          ),
                        },
                        {
                          validator: validateDatePicker,
                        },
                      ]}
                      onChangeValue={(date) => {
                        form.setFieldsValue({ endDate: date });
                      }}
                      startyear={moment().add(5, "year").year()}
                      endyear={moment().year()}
                      type="feature"
                    />
                  </Col>
                </Row> */}
                {/*
                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppInputNumber
                      prefix="日"
                      maxLength={11}
                      type="number"
                      pattern="[0-9]*"
                      rules={[
                        {
                          required: true,
                          message: t("error.enddate.required"),
                        },
                        {
                          validator(_: any, value: any) {
                            const realValue = Number(value);
                            if (realValue >= 1 && realValue <= 90) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "掲載終了日は1日以上90日以下を指定してください。"
                            );
                          },
                        },
                        {
                          validator(_: any, value: any) {
                            if (
                              product.status === ProductPublicStatus.Published
                            ) {
                              const realValue = Number(value);
                              if (realValue >= 1 && realValue <= 90) {
                                if (
                                  moment(product.publicDate).add(
                                    realValue,
                                    "day"
                                  ) < moment()
                                ) {
                                  return Promise.reject(
                                    "指定された掲載終了日は、すでに経過しているため指定できません。"
                                  );
                                }
                              }
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      label={t("editProduct.form.postingEndDate")}
                      name="settingDaysEndDate"
                      onKeyPress={(event) => {
                        if (CHARACTER_SPECIALS.includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        if (
                          CHARACTER_SPECIALS.some((char) =>
                            e.clipboardData.getData("text").includes(char)
                          )
                        ) {
                          return false;
                        }
                        form.setFieldsValue({
                          zipCode: e.clipboardData.getData("text"),
                        });
                      }}
                      onWheel={(e) => (e.target as any).blur()}
                      // formatter={(value: string) =>
                      //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      // }
                    ></AppInputNumber>
                  </Col>
                </Row> */}

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppTextField
                      name="publicDate"
                      label="商品公開日"
                      placeholder=""
                      disabled
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppTextField
                      name="endDate"
                      label="掲載終了日"
                      placeholder=""
                      disabled
                    />
                  </Col>
                </Row>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppTextField
                      extra={guidelineDayOfEndDate()}
                      prefix="日"
                      maxLength={11}
                      type="number"
                      pattern="[0-9]*"
                      formgroup={form}
                      onlyInputNumber
                      className="number-custom-app-text-field"
                      rules={[
                        {
                          required: true,
                          message: t("error.enddate.required"),
                        },
                        {
                          validator(_: any, value: any) {
                            const realValue = Number(value);
                            if (realValue >= 1 && realValue <= 120) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              "掲載終了日は1日以上120日以下を指定して下さい。"
                            );
                          },
                        },
                      ]}
                      label="商品の公開から"
                      name="settingDaysEndDate"
                      onKeyPress={(event) => {
                        if (CHARACTER_SPECIALS.includes(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                        if (
                          CHARACTER_SPECIALS.some((char) =>
                            e.clipboardData.getData("text").includes(char)
                          )
                        ) {
                          return false;
                        }
                        form.setFieldsValue({
                          zipCode: e.clipboardData.getData("text"),
                        });
                      }}
                      onWheel={(e) => (e.target as any).blur()}
                      // formatter={(value: string) =>
                      //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      // }
                    ></AppTextField>
                  </Col>
                </Row>

                <Row gutter={[8, 8]}>
                  <Col xs={24} md={8} xl={9}>
                    <AppCheckbox
                      type="reverse"
                      name="isAllowOnlineTours"
                      label=""
                      options={[
                        {
                          value: true,
                          label: t("editProduct.form.allowOnlineTours"),
                        },
                      ]}
                    />
                  </Col>
                </Row>

                <div className="edit-product-container__line">&nbsp;</div>

                <Row gutter={[16, 16]}>
                  <Col xs={24}>
                    <AppRadio
                      name="carryingCost"
                      label="搬出費用"
                      options={radioList}
                      rules={[
                        {
                          required: true,
                          message: t(
                            "editProduct.form.validate.shippingCost.required"
                          ),
                        },
                      ]}
                    />
                  </Col>
                </Row>

                <div className="content-container__note color-body">
                  {t("editProduct.form.note1")}
                </div>

                {/* <div className="edit-product-container__line">&nbsp;</div> */}
                
                {/* <Row gutter={[8, 8]}> */}
                {/*  <Col xs={24} md={8} xl={7}> */}
                {/*    <AppCheckbox */}
                {/*      onChange={(value) => */}
                {/*        setDisabledEstimationDate(!value.length) */}
                {/*      } */}
                {/*      type="reverse" */}
                {/*      name="isNeedTakeBackQuote" */}
                {/*      label="" */}
                {/*      options={[ */}
                {/*        { */}
                {/*          value: true, */}
                {/*          label: t("editProduct.form.needTakeBackQuote"), */}
                {/*        }, */}
                {/*      ]} */}
                {/*    /> */}
                {/*  </Col> */}
                {/* </Row> */}
                
                {/* <Row gutter={[16, 16]}> */}
                {/*  <Col xs={24}> */}
                {/*    <AppDatePicker */}
                {/*      disabled={disabledEstimationDate} */}
                {/*      values={ */}
                {/*        form.getFieldValue("estimationDate") */}
                {/*          ? form.getFieldValue("estimationDate") */}
                {/*          : undefined */}
                {/*      } */}
                {/*      form={form} */}
                {/*      name="estimationDate" */}
                {/*      label={t("editProduct.form.desiredDate")} */}
                {/*      rules={[ */}
                {/*        { */}
                {/*          required: !disabledEstimationDate, */}
                {/*          message: t( */}
                {/*            "editProduct.form.validate.desiredDate.required" */}
                {/*          ), */}
                {/*        }, */}
                {/*        { */}
                {/*          validator: validateDatePicker, */}
                {/*        }, */}
                {/*      ]} */}
                {/*      startyear={moment().add(5, "year").year()} */}
                {/*      endyear={moment().year()} */}
                {/*      type="feature" */}
                {/*      extra={t("editProduct.form.note2")} */}
                {/*    /> */}
                {/*  </Col> */}
                {/* </Row> */}

                <Mobile>
                  <div className="button-container">
                    <Row gutter={[32, 32]}>
                      <Col xs={12}>
                        <AppButton
                          buttontype="secondary"
                          onClick={() => {
                            handleBack();
                          }}
                        >
                          {t("editProduct.cancel")}
                        </AppButton>
                      </Col>

                      <Col xs={12}>
                        <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                          <AppButton
                            htmlType="submit"
                            buttontype="primary"
                            onClick={() => {
                              CommonService.scrollToError();
                            }}
                          >
                            {t("editProduct.save")}
                          </AppButton>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Mobile>

                <Desktop>
                  <div style={{ marginTop: "16px" }}>
                    <Row gutter={[32, 32]} justify="center">
                      <Col xs={6}>
                        <AppButton
                          buttontype="secondary"
                          onClick={() => {
                            handleBack();
                          }}
                        >
                          {t("editProduct.cancel")}
                        </AppButton>
                      </Col>

                      <Col xs={6}>
                        <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                          <AppButton
                            htmlType="submit"
                            buttontype="primary"
                            onClick={() => {
                              CommonService.scrollToError();
                            }}
                          >
                            {t("editProduct.save")}{""}
                          </AppButton>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Desktop>
              </div>
            </div>
          </Form>
        </Col>
      </Row>
    </div>
  );
}
