import React, { FC, memo, useEffect, useState, useRef } from "react";
import NumberFormat from "react-number-format";
import VerticalItem, {
  ItemViewType,
  ListViewType
} from "src/components/utils/vertical-list-item";
import {
  RightOutlined,
  EyeOutlined,
  HeartOutlined,
  ArrowRightOutlined,
  HeartFilled,
} from "@ant-design/icons";
import { isEmpty } from "lodash";
import Slider from "react-slick";
import { ProductItem, ProductTransactionStatus } from "src/models/product.model";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductService from "src/services/API/Product";
import AppHSkeleton from "src/components/skeleton/AppHSkeleton";
import AppVSkeleton from "src/components/skeleton/AppVSkeleton";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { filterByType } from "src/store/Product-Filter";
import AppImageView from "src/components/AppImageView";
import classNames from "classnames";
import { clearProduct, updateLike } from "src/store/Product";
import { Col, Row, Skeleton } from "antd";
import { AppDispatch, RootState } from "@store/configureStore";
import { AppBannerSlider, getListSlider, setMetaTitle } from "src/store/Global";
import { Desktop, Mobile } from "src/layouts/break-point";
import AppSkeletonSlider from "src/components/skeleton/AppSkeletonSlider";
import GlobalService from "src/services/API/Global";
import { DeviceType, MessageType } from "src/models/common.model";
import { useMediaQuery } from "react-responsive";
import StorageService from '@service/Storage';
import AppModal from '@components/AppModal';
import { PATH_NAME } from 'src/configs';
import AppHelmet from "src/components/AppHelmet";
import { ItemDesktop } from "../item-desktop";
import { FavoriteItems } from "../../my-page/components/Favorite/Favorite.model";
import FavoriteItem from "../../my-page/components/Favorite/FavoriteItem";
import { getListBanner } from "../../../store/Global/index";
import CommonService from "../../../services/Common/index";

interface HeaderList {
  name: string;
  caption: string;
  isAction?: boolean;
  type: "PICKUP" | "RECENTLY" | "FAVORITE";
}

const SliderItem: FC<AppBannerSlider> = ({ ...props }) => {
  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );
  return (
    <div
      className="slider-item"
      style={{ height: `calc((100vw - 32px) / 7 * 3)` }}
      onClick={() => {
        GlobalService.handleClickBanner(props.id);
        CommonService.openURL(props.externalUrl);
      }}
    >
      <img
        style={{ height: `calc((100vw - 32px) / 7 * 3)` }}
        src={props.imageUrl.concat(sasCode as string)}
        alt=""
      />
    </div>
  );
};
 

const HeaderItem: FC<HeaderList> = ({ ...props }) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  return (
    <div className="header-list ">
      <div className="row-1">
        <div className="title bold text-body-2">{translate(props.name)}</div>
        {props.isAction ? (
          <div
            className="action"
            onClick={() => {
              dispatch(filterByType(props.type));
            }}
          >
            <div className="action-text text-body-4">
              {translate("dashboard.goto")}
            </div>
            <RightOutlined />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const HorizontalItem: FC<ProductItem> = ({ ...props }) => {
  const navigate = useNavigate();
  const [item, setItem] = useState<ProductItem>(props);
  const [loadingLike, setLoadingLike] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const isLogined = useSelector(
    (state: RootState) => state.UserReducer.isLogin
  );
  const likeStatus = useSelector(
    (state: RootState) => state.ProductReducer.likeStatus
  );
  const user = useSelector((state: RootState) => state.UserReducer.userInfo);
  const isMyProduct = item?.userId === user?.id;
  const [confirmLogin, setConfirmLogin] = useState<boolean>(false);

  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );

  useEffect(() => {
    if (likeStatus?.id === props.id) {
      setItem((item) => ({
        ...item,
        canRemoveFromFavorite: likeStatus.status,
        favoriteCount: likeStatus.count,
      }));
    }
  }, [likeStatus]);

  async function handleLike() {
    setLoadingLike(true);
    const response = await dispatch(updateLike(item));

    if (updateLike.rejected.match(response)) {
      setLoadingLike(false);
    }
    if (updateLike.fulfilled.match(response)) {
      setLoadingLike(false);
    }
  }

  function goToDetail(params: any) {
    navigate(`/product-detail/${params.id}`);
  }

  function getImage() {
    if (!item || !(item.productMedias || []).length) {
      // return "/assets/images/fake3.png";
      return "";
    }
    const firstImage = item.productMedias.filter((f) => f.type === 1)[0];
    // return firstImage ? firstImage.url.concat(sasCode as string) : "/assets/images/fake3.png";
    return firstImage ? firstImage.url.concat(sasCode as string) : "";
  }

  function getColor(status: ProductTransactionStatus) {
    return "#FFA726";
  }

  function getBackground(status: ProductTransactionStatus) {
    return "#FFEACC";
  }

  return (
    <>
      <AppModal
        visible={confirmLogin}
        title="この先の機能はログインが必要となります。"
        content=""
        okText="ログイン"
        cancelText="閉じる"
        onOk={() => navigate(PATH_NAME.LOGIN)}
        onCancel={() => {
          setConfirmLogin(false);
        }}
      />
      <div className="list-slider-item">
        {(!isMyProduct || !isLogined) && (
          <div
            className={classNames("button-like", "color-primary", {
              disabled: loadingLike,
            })}
            onClick={() => {
              if (!isLogined) {
                setConfirmLogin(true);
                return;
              }
              handleLike();
            }}
          >
            {item.canRemoveFromFavorite ? <HeartFilled /> : <HeartOutlined />}
          </div>
        )}

        <AppImageView
          onClick={() => goToDetail({ id: props.id })}
          src={getImage()}
        />

        <div
          className="product-title color-title bold text-body-4 three-dot"
          onClick={() => goToDetail({ id: props.id })}
        >
          {item.title}
        </div>
        <div className="product-des color-title bold text-body-4">
          <span className="three-dot">{props.maker} </span>
          <span className="min-w-110 pl-10">
          {item?.yearOfManufacture
            ? item?.yearOfManufacture >= 1980
              ? `${item?.yearOfManufacture}年式`
              : "1980年以前"
            : "年式不明"}
        </span>
        </div>
        <div
          className="product-price color-primary bold text-body-2"
          onClick={() => goToDetail({ id: props.id })}
        >
          <span className="text-caption-2">¥</span>
          <NumberFormat
            value={props.price}
            displayType="text"
            thousandSeparator
          />
          {item?.orderStatus && (
              <span
                className="text-action-search text-caption-2"
                style={{
                  background: getBackground(item?.orderStatus),
                  color: getColor(item?.orderStatus),
                }}
              >
              取引中
            </span>
            )}
        </div>
        <div
          className="product-action"
          onClick={() => goToDetail({ id: props.id })}
        >
          <EyeOutlined />
          <span className="text-value">
          {item.viewCount > 999 ? "999+" : item.viewCount}
        </span>
          <HeartOutlined />
          <span className="text-value">
          {item.favoriteCount > 999 ? "999+" : item.favoriteCount}
        </span>

          <div className="action" onClick={() => goToDetail({ id: props.id })}>
            <ArrowRightOutlined />
          </div>
        </div>
      </div>
    </>
  );
};
const settingsTop = {
  className: "slider variable-width",
  dots: false,
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
  autoplay: true,
  autoplaySpeed: 5000,
};

const SliderBar = memo(() => {
  const dispatch = useDispatch<AppDispatch>();
  const listSlider = useSelector(
    (state: RootState) => state.GlobalReducer.sliders
  );

  // useEffect(() => {
  //   dispatch(getListSlider());
  //   dispatch(getListBanner());
  // }, []);

  return (
    <div className="slider-container">
      {isEmpty(listSlider) ? (
        <AppSkeletonSlider />
      ) : (
        <Slider {...settingsTop}>
          {listSlider.map(
            (item, index) =>
              (item.device === DeviceType.APP ||
                item.device === DeviceType.BOTH) && (
                <SliderItem {...item} key={index}></SliderItem>
              )

            // ((!isDesktop && item.device === DeviceType.APP) ||
            //   item.device === DeviceType.BOTH) && (
            //   <SliderItem {...item} key={index}></SliderItem>
            // )
          )}
        </Slider>
      )}
    </div>
  );
});

function PrevArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={classNames(className, "button-slider")} onClick={onClick}>
      <img className="prev" src="/assets/icons/arr-right.svg" alt="" />
    </div>
  );
}
function NextArrow(props: any) {
  const { className, onClick } = props;
  return (
    <div className={classNames(className, "button-slider")} onClick={onClick}>
      <img className="next" src="/assets/icons/arr-left.svg" alt="" />
    </div>
  );
}

const settingsDesktop = {
  className: "slider variable-width",
  dots: false,
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: false,
  autoplay: true,
  autoplaySpeed: 5000,
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
};

const SliderBarDesktop = memo(() => {
  const dispatch = useDispatch<AppDispatch>();
  const listSlider = useSelector(
    (state: RootState) => state.GlobalReducer.sliders
  );

  // useEffect(() => {
  //   if (isEmpty(listSlider)) {
  //     dispatch(getListSlider());
  //   }
  // }, []);

  return (
    <div className="slider-container-desktop">
      {isEmpty(listSlider) ? (
        <AppSkeletonSlider />
      ) : (
        <Slider {...settingsDesktop}>
          {listSlider.map(
            (item, index) =>
              (item.device === DeviceType.WEB ||
                item.device === DeviceType.BOTH) && (
                <SliderItem {...item} key={index}></SliderItem>
              )
          )}
        </Slider>
      )}
    </div>
  );
});

const settings = {
  className: "slider variable-width",
  dots: false,
  infinite: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
};

const GetListFavoriteProduct = ({
  ...props
}: {
  listItem: FavoriteItems[];
  isLoading: boolean;
  changeFavorite: () => void;
}) => { 
  const navigate = useNavigate();
  return (
  <div className="v-list-container">
  <HeaderItem
    name="dashboard.favorite"
    caption="Products were registered to the TOP screen."
    isAction={false}
    type="FAVORITE"
  ></HeaderItem>

  {props.isLoading ? (
    <>
      <AppVSkeleton></AppVSkeleton>
      <AppVSkeleton></AppVSkeleton>
    </>
  ) : (
    props.listItem.map((product: FavoriteItems) => (
      <FavoriteItem
        key={product.productId}
        item={product}
        onReload={props.changeFavorite}
      ></FavoriteItem>
    ))
  )}
  </div>
  );
};


const DesktopFavoriteListProduct = ({
  ...props
}: {
  listItem: FavoriteItems[];
  isLoading: boolean;
  title: string;
  type: "FAVORITE";
  changeFavorite: () => void;
}) => (
  <div className="lg-list-container h-list-container">
    <HeaderItem
      name={props.title}
      caption="Products were registered to the TOP screen."
      isAction={false}
      type={props.type}
    ></HeaderItem>
    {props.isLoading ? (
      <Row gutter={16}>
        <Col span={8}>
          <AppHSkeleton></AppHSkeleton>
        </Col>
        <Col span={8}>
          <AppHSkeleton></AppHSkeleton>
        </Col>
        <Col span={8}>
          <AppHSkeleton></AppHSkeleton>
        </Col>
      </Row>
    ) : (
      // <div className="list-slider-container">
      <Row gutter={0}>
        {props.listItem.map((product: FavoriteItems) => (
          <Col span={8} key={product.productId}>
            <FavoriteItem
              key={product.productId}
              item={product}
              onReload={props.changeFavorite}
            ></FavoriteItem>
          </Col>
        ))}
      </Row>
      // </div>
    )}
  </div>
);

const HorizontalListProduct = ({
  ...props
}: {
  listItem: ProductItem[];
  isLoading: boolean;
  changeFavorite: () => void;
// }) => (
//   <div className="h-list-container">
//     <HeaderItem
//       name="dashboard.pickup"
//       caption="Products were registered to the TOP screen."
//       isAction
//       type="PICKUP"
//     ></HeaderItem>
//     {props.isLoading ? (
//       <AppHSkeleton></AppHSkeleton>
//     ) : (
//       <div className="list-slider-container">
//         <Slider {...settings}>
//           {props.listItem.map((product: ProductItem) => (
//             <div
//               key={product.id}
//               style={{ width: (window.innerWidth * 70) / 100, marginRight: 16 }}
//             >
//               <HorizontalItem {...product}></HorizontalItem>
//             </div>
//           ))}
//         </Slider>
//       </div>
//     )}
//   </div>
// );
}) => {
  const navigate = useNavigate();
  return (
    <div className="v-list-container">
      <HeaderItem
        name="dashboard.pickup"
        caption="Products were registered to the TOP screen."
        isAction
        type="PICKUP"
      ></HeaderItem>

      {props.isLoading ? (
        <>
          <AppVSkeleton></AppVSkeleton>
          <AppVSkeleton></AppVSkeleton>
        </>
      ) : (
        props.listItem.map((product: ProductItem) => (
          <VerticalItem
            key={product.id}
            item={product}
            type={ItemViewType.DASHBOARD}
            onClick={() => navigate(`/product-detail/${product.id}`)}
            changeFavorite={() => 
                props.changeFavorite()
            }
            listtype={ListViewType.PICKUP}
          ></VerticalItem>
        ))
      )}
    </div>
  );
};

const VerticalListProduct = ({
  ...props
}: {
  listItem: ProductItem[];
  isLoading: boolean;
  changeFavorite: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <div className="v-list-container">
      <HeaderItem
        name="dashboard.recently"
        caption="Newly registered machining machines."
        isAction
        type="RECENTLY"
      ></HeaderItem>

      {props.isLoading ? (
        <>
          <AppVSkeleton></AppVSkeleton>
          <AppVSkeleton></AppVSkeleton>
        </>
      ) : (
        props.listItem.map((product: ProductItem) => (
          <VerticalItem
            key={product.id}
            item={product}
            type={ItemViewType.DASHBOARD}
            onClick={() => navigate(`/product-detail/${product.id}`)}
            changeFavorite={() => 
              props.changeFavorite()
            }
            listtype={ListViewType.RECENTLY}
          ></VerticalItem>
        ))
      )}
    </div>
  );
};

const PromotionListProduct: FC<{ listBanner: AppBannerSlider[] }> = ({
  ...props
}) => {
  const sasCode = useSelector(
    (state: RootState) => state.GlobalReducer.sasCode
  );
  return (
    <div className="v-list-container">
      {props.listBanner.map(
        (item, index) =>
          (item.device === DeviceType.APP || item.device === DeviceType.BOTH) && (
            <div
              key={index}
              className="img-slider banner"
              style={{ height: `calc((100vw - 32px) / 7 * 3)` }}
              onClick={() => {
                GlobalService.handleClickBanner(item.id);
                CommonService.openURL(item.externalUrl);
              }}
            >
              <img
                style={{ height: `calc((100vw - 32px) / 7 * 3)` }}
                src={item.imageUrl?.concat(sasCode as string)}
                alt=""
              />
            </div>
          )
      )}
    </div>
  );
};

const DesktopListProduct = ({
  ...props
}: {
  listItem: ProductItem[];
  isLoading: boolean;
  title: string;
  type: "PICKUP" | "RECENTLY";
  changeFavorite?: () => void;
}) => (
  <div className="lg-list-container h-list-container">
    <HeaderItem
      name={props.title}
      caption="Products were registered to the TOP screen."
      isAction
      type={props.type}
    ></HeaderItem>
    {props.isLoading ? (
      <Row gutter={16}>
        <Col span={8}>
          <AppHSkeleton></AppHSkeleton>
        </Col>
        <Col span={8}>
          <AppHSkeleton></AppHSkeleton>
        </Col>
        <Col span={8}>
          <AppHSkeleton></AppHSkeleton>
        </Col>
      </Row>
    ) : (
      // <div className="list-slider-container">
      <Row gutter={16}>
        {props.listItem.map((product) => (
          <Col span={8} key={product.id}>
            
            <ItemDesktop
             product={product}
             changeFavorite={props.changeFavorite}
             listtype={(props.type === "PICKUP") ? ListViewType.PICKUP : ListViewType.RECENTLY}
            ></ItemDesktop>
          </Col>
        ))}
      </Row>
      // </div>
    )}
  </div>
);

const DashboardScreen: FC = () => {
  const [loadingListPickup, setLoadingListPickup] = useState(false);

  const [loadingListRecently, setLoadingListRecently] = useState(false);

  const [listProductPickUp, setListProductPickUp] = useState<ProductItem[]>([]);

  const [listProductRecently, setListProductRecently] = useState<ProductItem[]>(
    []
  );

  const [loadingSlider, setLoadingSlider] = useState<boolean>(false);

  const listBanner = useSelector(
    (state: RootState) => state.GlobalReducer.banner
  );

  const listSlider = useSelector(
    (state: RootState) => state.GlobalReducer.sliders
  );

  const [searchParam, setSearchParam] = useSearchParams();
  const page = useRef(0);
  const numberOfPage = 6;
  const [listFavorite, setListFavorite] = useState<FavoriteItems[]>([]);
  const [loadingListFavorite, setLoadingListFavorite] = useState(false);
  const isLogined = useSelector(
    (state: RootState) => state.UserReducer.isLogin
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setLoadingListPickup(true);
    ProductService.getListProductDashboard(true, false)
      .then((res) => {
        setListProductPickUp(res.data.result.items || []);
        setLoadingListPickup(false);
      })
      .catch((err) => {
        setLoadingListPickup(false);
      });

    setLoadingListRecently(true);

    ProductService.getListProductDashboard(false, true)
      .then((res) => {
        setListProductRecently(res.data.result.items || []);
        setLoadingListRecently(false);
      })
      .catch((err) => {
        setLoadingListRecently(false);
      });

    // お気に入り商品の取得
    if (isLogined) {
      setLoadingListFavorite(true);
      ProductService.getListFavorite(
          window.innerWidth > 768
            ? ((((Number(searchParam.get("currentPage")) || 1) - 1) *
                numberOfPage) as number)
            : page.current * numberOfPage,
          numberOfPage
        )
      // setLoadingListFavorite(true);
      // ProductService.getListFavorite( 0, 2 )
        .then((res) => {
          setListFavorite(res.data.result.items || []);
          setLoadingListFavorite(false);
        })
        .catch((err) => {
          setLoadingListFavorite(false);
        });  
    }

    return () => {
      dispatch(clearProduct());
    };
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingSlider(true);
      try {
        await dispatch(getListSlider());
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingSlider(false);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(setMetaTitle("マシプラ | インターネットやモバイルアプリを使って、中古工作機械や工具を売買する日本初のサービスです。"));
    return () => {
      dispatch(setMetaTitle(''));
    };
  }, []);

  function changeFavorite() : void {
    if (isLogined) {
      // setLoadingListFavorite(true);
      ProductService.getListFavorite(
          window.innerWidth > 768
            ? ((((Number(searchParam.get("currentPage")) || 1) - 1) *
                numberOfPage) as number)
            : page.current * numberOfPage,
          numberOfPage
        )
        .then((res) => {
          setListFavorite(res.data.result.items || []);
          // setLoadingListFavorite(false);
        })
        .catch((err) => {
          // setLoadingListFavorite(false);
        });  
    }
  }

  return (
    <>
      <Mobile>
        <div
          className="slider-container"
          style={{
            marginTop: isEmpty(listSlider) && !loadingSlider ? "-24px" : "0",
          }}
        >
          {loadingSlider ? (
            <AppSkeletonSlider style={{ height: "200px" }} />
          ) : isEmpty(listSlider) ? null : (
            <SliderBar></SliderBar>
          )}
          {/*
                {isLogined && (
                  <GetListFavoriteProduct
                    listItem={listFavorite}
                    isLoading={loadingListFavorite}
                    changeFavorite={changeFavorite}
                  ></GetListFavoriteProduct>
                )} 
        */}

          <VerticalListProduct
            listItem={listProductRecently}
            isLoading={loadingListRecently}
            changeFavorite={changeFavorite}
          ></VerticalListProduct>

          <HorizontalListProduct
            isLoading={loadingListPickup}
            listItem={listProductPickUp}
            changeFavorite={changeFavorite}
          ></HorizontalListProduct>

          <PromotionListProduct listBanner={listBanner}></PromotionListProduct>
        </div>
      </Mobile>

      <Desktop>
         <div style={{ marginTop: (isEmpty(listSlider) && !loadingSlider) ? '-42px' : '0' }}>
          {
            loadingSlider
              ? (<AppSkeletonSlider style={{ height: '260px' }} />)
              : (isEmpty(listSlider) ? null : <SliderBarDesktop></SliderBarDesktop>)
          }

          <DesktopListProduct
            isLoading={loadingListRecently}
            listItem={listProductRecently}
            title="dashboard.recently"
            type="RECENTLY"
            changeFavorite={changeFavorite}
          ></DesktopListProduct>

          <DesktopListProduct
            isLoading={loadingListPickup}
            listItem={listProductPickUp}
            title="dashboard.pickup"
            type="PICKUP"
            changeFavorite={changeFavorite}
          ></DesktopListProduct>

         </div>
      </Desktop>
    </>
  );
};
export default memo(DashboardScreen);
